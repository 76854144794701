import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { useAuthStore } from '@/modules/auth/stores/auth-store'

// AKA "Group", but group is reserved in Ruby
type Workspace = {
  id: string | number
  name: string
  subdomain: string
  //
  options: {
    enable_investor_aml_verification: boolean
    is_company_list_enabled: boolean
    is_setup_mode_enabled: boolean
    hide_investors_from_investors: boolean
    show_chapters_to_investors: boolean
    can_access_rails: boolean
    is_aml_enabled: boolean
  }
  //
  entity_count: number
  has_managed_entities: boolean
  individual_count: number
  fund_count: number
  spv_count: number
}

const initialState: Workspace = {
  id: null,
  name: null,
  subdomain: null,
  options: {
    enable_investor_aml_verification: false,
    is_company_list_enabled: false,
    is_setup_mode_enabled: false,
    hide_investors_from_investors: true,
    show_chapters_to_investors: false,
    can_access_rails: false,
    is_aml_enabled: false,
  },
  entity_count: 0,
  has_managed_entities: false,
  individual_count: 0,
  fund_count: 0,
  spv_count: 0,
}

export const useWorkspaceStore = defineStore('workspaceStore', () => {
  const route = useRoute()
  const baseUrl = computed(() => `/${route.params.slug}`)
  const current = ref({ ...initialState })
  const hidden_keys = {
    elpiscapital: {
      all: [
        'investments.cash_position',
        'investments.initial_value',
        'investments.roi',
        'investments.moic',
        'investments.initial_price',
        'invesments.initial_shares',
        'invesments.initial_capital',
        'investments.distributions',
        'investments.accrued_interest',
      ],
      // admin: [],
      // individual: [],
    },
  }

  const fetchCurrent = async (slug?: string) => {
    const url = slug ? `/${slug}` : baseUrl.value
    const { data } = await useFetch(`${url}/workspace`).get().json<{ data: Workspace }>()
    current.value = data.value.data
  }

  const reset = () => {
    current.value = { ...initialState }
  }

  const isKeyHidden = (key: string, isAdmin = false) => {
    if (!current.value.subdomain) return true
    const authStore = useAuthStore()

    const subdomain_hidden_keys = hidden_keys[current.value.subdomain]
    const all_key_array = subdomain_hidden_keys?.all
    if (all_key_array && all_key_array.includes(key)) return true

    if (isAdmin || authStore.is_site_or_group_admin) {
      const admin_key_array = subdomain_hidden_keys?.admin
      if (admin_key_array && admin_key_array.includes(key)) return true
      return false
    }

    // individual
    const individual_key_array = subdomain_hidden_keys?.individual
    if (individual_key_array && individual_key_array.includes(key)) return true

    return false
  }

  return {
    hidden_keys,
    current,
    fetchCurrent,
    reset,
    isKeyHidden,
  }
})
